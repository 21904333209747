/*
 * @Description:
 * @Autor: lichao
 * @Date: 2022-01-05 09:54:38
 * @LastEditors: lichao
 * @LastEditTime: 2022-01-05 16:12:10
 */
import { useLocation } from 'react-router'
import {useEffect} from "react";
import { renderRoutes } from "./router/index";
import { Switch } from "react-router-dom";
import routes from "./router/routes";
import Menu from "./components/Menu";
import SiteMapFooter from "./components/SiteMapFooter";
import "./App.css";
function App() {
    useEffect(()=> {
        //友盟统计数据，单页面应用需要监听路由跳转
        const hm=document.createElement('script');
        hm.src="https://s4.cnzz.com/z_stat.php?id=1280778114&web_id=1280778114";
        const s=document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(hm,s)
    }, [useLocation()])
  const location = useLocation();
  console.log(location)
  return (
    <div className="App">
      {
        location.pathname === '/invbase/introduce' || location.pathname.includes('download')?(
          <Switch>{renderRoutes(routes)}</Switch>
        ) : (
          <div>
            <Menu></Menu>
            <Switch>{renderRoutes(routes)}</Switch>
            <SiteMapFooter></SiteMapFooter>
          </div>
        )
      }
    </div>

  );
}

export default App;
