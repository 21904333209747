/*
 * @Description:
 * @Autor: lichao
 * @Date: 2021-12-08 14:28:31
 * @LastEditors: lichao
 * @LastEditTime: 2022-01-18 09:07:46
 */

import { lazyLoad } from "./index";

// component
const Home = lazyLoad(() => import("../page/Home"));
const Course = lazyLoad(() => import("../page/Course"));
const CourseHome = lazyLoad(() => import("../page/Course/CourseHome"));
//专题和小镇投资说专题列表
const CourseSpecialList=lazyLoad(()=>import("../page/Course/CourseSpecialList"));
const CourseSpecial = lazyLoad(() => import("../page/Course/CourseSpecial"));
const CourseArticle = lazyLoad(() => import("../page/Course/CourseArticle"));
const CourseVideo = lazyLoad(() => import("../page/Course/CourseVideo"));
// const CourseList = lazyLoad(() => import("../page/Course/CourseList"));
const InvBase = lazyLoad(() => import("../page/InvBase"));
const InvBaseHome = lazyLoad(() => import("../page/InvBase/InvBaseHome"));
const InvProtect = lazyLoad(() => import("../page/InvBase/InvProtect"));
const InvBaseVisit = lazyLoad(() => import("../page/InvBase/InvBaseVisit"));
const InvBaseApply = lazyLoad(() => import("../page/InvBase/InvBaseApply"));
// h5 新页面
const InvBaseIntroduce = lazyLoad(()=>import("../page/InvBase/InvBaseIntroduce"))
const News = lazyLoad(() => import("../page/News"));
const NewsHome = lazyLoad(() => import("../page/News/NewsHome"));
const NewsArticle = lazyLoad(() => import("../page/News/NewsArticle"));
const ReadHome = lazyLoad(() => import("../page/Read/ReadHome"));
const Read = lazyLoad(() => import("../page/Read"));
const Interact = lazyLoad(() => import("../page/Interact"));
const InteractHome = lazyLoad(() => import("../page/Interact/InteractHome"));
const Special = lazyLoad(() => import("../page/Read/ReadSpecial"));
const ReadArticle = lazyLoad(() => import("../page/Read/ReadArticle"));
const Satisfaction = lazyLoad(() => import("../page/Satisfaction"));
const SatisfactionHome = lazyLoad(() => import("../page/Satisfaction/SatisfactionHome"));
const Quiz=lazyLoad(()=>import("../page/Quiz"))
const QuizHome=lazyLoad(()=>import("../page/Quiz/QuizHome"))
const ActivitySatisfaction=lazyLoad(()=>import("../page/ActivitySatisfaction"))
const ActivitySatisfactionHome=lazyLoad(()=>import("../page/ActivitySatisfaction/SatisfactionHome"))
// const NotFound = lazyLoad(() => import("../page/NotFound"));
// routes
const routes = [
  {
    path: "/home",
    component: Home,
    exact: false,
    title: "北京基金小镇投教基地",
    children: [],
  },
  {
    path: "/course",
    component: Course,
    title: "投资课堂",
    exact: false,
    children: [
      // 课程首页
      {
        path: "/course",
        component: CourseHome,
        title: "投资课堂",
        exact: true,
        children: [],
      },
      //专题和小镇投资说合集列表  Collection合集  为nc显示special专题列表 ssid为0是专题列表 为1是小镇投资说
      {
        path: "/course/speciallist/:cid",
        component: CourseSpecialList,
        title: "投资课堂",
        exact: true,
        children: [],
      },
      // 课程专题列表
      {
        path: "/course/special/:id",
        component: CourseSpecial,
        title: "投资课堂",
        exact: true,
        children: [],
      },
      // 课程详情
      {
        path: "/course/article/:id",
        component: CourseArticle,
        title: "投资课堂",
        exact: true,
        children: [],
      },
      // 课程视频专题播放页
      {
        path: "/course/video/:id/:sid",
        title: "投资课堂",
        component: CourseVideo,
        exact: true,
      },
      // // 课程列表
      // {
      //   path: "/course/list",
      //   component: CourseList,
      //   exact: true,
      //   meta: [{ name: "投教课堂", path: "/course" }],
      // },
      // {
      //   component: NotFound,
      // },
    ],
  },
  {
    path: "/read",
    component: Read,
    title: "投资必读",
    exact: false,
    children: [
      {
        path: "/read",
        title: "投资必读",
        component: ReadHome,
        exact: true,
        children: [],
      },

      {
        path: "/read/special/:id",
        title: "投资必读",
        component: Special,
        exact: true,
        children: [],
      },
      {
        path: "/read/article/:id",
        component: ReadArticle,
        exact: true,
      },
    ],
  },
  {
    path: "/news",
    component: News,
    title: "投教动态",
    exact: false,
    children: [
      // 课程首页
      {
        path: "/news",
        component: NewsHome,
        title: "投教动态",
        exact: true,
        children: [],
      },
      // 课程专题列表
      {
        path: "/news/article/:id",
        title: "投教动态",
        component: NewsArticle,
        exact: true,
        meta: [{ name: "投教动态", path: "/news" }],
        children: [],
      },
      // {
      //   component: NotFound,
      // },
    ],
  },
  {
    path: "/interact",
    component: Interact,
    title: "互动体验",
    exact: false,
    children: [
      {
        title: "互动体验",
        path: "/interact",
        component: InteractHome,
        exact: true,
        children: [],
      },
      // {
      //   component: NotFound,
      // },
    ],
  },
    //投资者满意度专用页面
  {
    path: "/satisfaction",
    component: Satisfaction,
    title: "投资者满意度",
    exact: false,
    children: [
      {
        title: "投资者满意度",
        path: "/satisfaction",
        component: SatisfactionHome,
        exact: true,
      }
    ]
  },
  {
    path: "/quiz",
    component: Quiz,
    title: "知识问答",
    exact: false,
    children: [
      {
        title: "知识问答",
        path: "/quiz",
        component: QuizHome,
        exact: true,
      }
    ]
  },
  {
    path: "/satisfaction_activity",
    component: ActivitySatisfaction,
    title: "投资者满意度",
    exact: false,
    children: [
      {
        title: "投资者满意度",
        path: "/satisfaction_activity",
        component: ActivitySatisfactionHome,
        exact: true,
      }
    ]
  },
  //
  {
    path: "/invbase",
    component: InvBase,
    title: "相约基地",
    exact: false,
    children: [
      {
        path: "/invbase",
        component: InvBaseHome,
        exact: true,
        title: "相约基地",
        children: [],
      },
      {
        path: "/invbase/protect",
        title: "相约基地",
        component: InvProtect,
        exact: true,
      },
      {
        path: "/invbase/apply",
        component: InvBaseApply,
        title: "合作申请",

        exact: true,
      },
      {
        path: "/invbase/visit",
        component: InvBaseVisit,
        title: "参观预约",

        exact: true,
      },
      // 新h5 页面
      {
        path: "/invbase/introduce",
        component: InvBaseIntroduce,
        title: "北京基金小镇研究院",
        exact: true,
      },
    ],
  },
  {
    path: "/",
    redirect: "/home",
    exact: true,
  },
  // {
  //   path: "/404",
  //   component: NotFound,
  // },
  // {
  //   component: NotFound,
  // },
];

export default routes;
