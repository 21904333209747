/*
 * @Description: 站点地图
 * @Autor: lichao
 * @Date: 2022-01-18 09:13:15
 * @LastEditors: lichao
 * @LastEditTime: 2022-02-17 15:14:24
 */
import "./index.less";
const SiteMapFooter = () => {
  return (
    <div className="site-footer">
      <div className="top">
        <div className="info">
          <img src="/image/footer-brand.png" alt="" />
          <ul>
            <li>
              {" "}
              <img src="/image/tel.png" alt="" />
              联系电话：010-61336961
            </li>
            <li>
              {" "}
              <img src="/image/email.png" alt="" />
              联系邮箱：news@bjfundtown.com
            </li>
            <li>
              {" "}
              <img src="/image/address.png" alt="" />
              基地地址：北京基金小镇国际会议中心
            </li>
          </ul>
        </div>
        <div className="link">
          <ul>
            <li>
              <a
                href="http://wap.beijingfundtown.com/"
                target="_blank"
                rel="noreferrer"
              >
                云端 · 北京基金小镇
              </a>
            </li>
            <li>
              <a
                href="http://m.beijingfundtown.com"
                target="_blank"
                rel="noreferrer"
              >
                北京基金小镇
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="bottom">京ICP备16008758号-1</div>
    </div>
  );
};
export default SiteMapFooter;
