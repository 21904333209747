/*
 * @Description: 菜单
 * @Autor: lichao
 * @Date: 2022-01-18 09:13:15
 * @LastEditors: lichao
 * @LastEditTime: 2022-02-17 15:14:39
 */
import { Collapse } from "zarm";
import menu from "../../static/img/menu-btn.png";
import close from "../../static/img/menu-btn-close.png";
import { useState, useEffect } from "react";
import { NavLink, withRouter } from "react-router-dom";
import "./index.less";

const MenuList = [
  { name: "首页", path: "/home" },
  {
    name: "投资课堂",
    path: "",
    children: [
      { name: "投资者课堂", path: "/course" },
      {
        name: "管理人课堂",
        path: "http://wap.beijingfundtown.com/course",
        out: true,
      },
    ],
  },
  { name: "投资必读", path: "/read" },
  { name: "投资动态", path: "/news" },
  { name: "互动体验", path: "/interact" },
  { name: "相约基地", path: "/invbase" },
];

const Menu = (props) => {
  const {
    location: { pathname },
  } = props;
  const [vis, setVis] = useState(0);
  const [activeNavPath, setActiveNavPath] = useState("");
  const getMenuList = (menuList) =>
    menuList.map((m, mi) => (
      <li
        key={mi}
        onClick={() => {
          document.body.style.position = "unset";
          setVis(!vis);
        }}
        className={m.children ? "liitem" : ""}
      >
        {m.children ? (
          <Collapse onClick={(e) => e.stopPropagation()}>
            <Collapse.Item key="1" title="投资课堂">
              <div className="collapselist">
                {m.children.map((item, itemi) => {
                  return item.out ? (
                    <a
                      key={itemi}
                      href={item.path}
                      onClick={() => {
                        document.body.style.position = "unset";
                        setVis(!vis);
                      }}
                    >
                      {item.name}
                    </a>
                  ) : (
                    <NavLink
                      key={itemi}
                      to={item.path}
                      activeClassName="menu-active"
                      onClick={() => {
                        document.body.style.position = "unset";
                        setVis(!vis);
                      }}
                      className={
                        activeNavPath === item.path ? "menu-active" : ""
                      }
                    >
                      {item.name}
                    </NavLink>
                  );
                })}
              </div>
            </Collapse.Item>
          </Collapse>
        ) : (
          <NavLink
            to={m.path}
            activeClassName="menu-active"
            className={activeNavPath === m.path ? "menu-active" : ""}
          >
            {m.name}
          </NavLink>
        )}
      </li>
    ));

  useEffect(() => {
    let isNews =
      pathname.indexOf("news") > 0 ||
      pathname.indexOf("notice") > 0 ||
      pathname.indexOf("media") > 0 ||
      pathname.indexOf("focus") > 0;
    let isFou =
      pathname.indexOf("foundation") > 0 ||
      pathname.indexOf("introduce") > 0 ||
      pathname.indexOf("project") > 0 ||
      pathname.indexOf("info") > 0;
    if (isNews) setActiveNavPath("/news");
    else if (isFou) setActiveNavPath("/foundation");
    else setActiveNavPath(pathname);
  }, [pathname]);
  return (
    <header className="menu">
      <div className="menu-content">
        <img src="/image/logo.png" alt="" />
        <img
          src={vis ? close : menu}
          alt=""
          onClick={() => {
            vis
              ? (document.body.style.position = "unset")
              : (document.body.style.position = "fixed");
            setVis(!vis);
          }}
        />
      </div>
      <ul
        className="menu-list"
        style={{
          display: vis ? "flex" : "none",
        }}
      >
        {getMenuList(MenuList)}
      </ul>
      <div
        className="menu-mask"
        onClick={() => {
          document.body.style.position = "unset";
          setVis(0);
        }}
        style={{ display: vis ? "block" : "none" }}
      ></div>
    </header>
  );
};
export default withRouter(Menu);
